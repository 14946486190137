import React from "react";
import Slider from "react-slick";
import {StaticImage} from "gatsby-plugin-image";

import "./Tdswelcome.css"

const bottleJack = '../images/bottleJack.png';
const pipeBender = '../images/pipeBender.png';
const engineCrane = '../images/enginCrane.png';
const gearJack = '../images/gearJack.png';
const palletStacker = '../images/palletStacker.png';
const trolleyJack = '../images/trolleyJack.png';
const palletJack = '../images/palletJack.png';
const positionJack = '../images/positionJack.png';
const carLifter = '../images/carLifter.png';

function CarouselArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className + " welcome-carousel-arrow"}
            style={{ ...style}}
            onClick={onClick}
            role={"button"}
        />
    );
}

export default function Tdswelcome() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        initialSlide: 0,
        dotsClass: "slick-dots welcome-carousel-dots",
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        nextArrow: <CarouselArrow />,
        prevArrow: <CarouselArrow />,
        responsive: [
            {
                breakpoint: 1120,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: true
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };

    return (
        <>
            <div className="title-box">
                <h2>Welcome to <b>Opal Hydraulic</b></h2>
                <p>
                    We repair and restore <strong>all types</strong> of industrial equipment that works with manual hydraulic jacks:
                </p>
            </div>
            <Slider {...settings} className={"welcome-slider"}>
                <div className="wel-box coman-box">
                    <StaticImage objectFit="contain" src={bottleJack}  alt={"Bottle Jack"} className="welcomeImage"/>
                    <h5>Bottle Jacks</h5>
                </div>
                <div className="wel-box coman-box">
                    <StaticImage objectFit="contain" src={gearJack}  alt={"Transmission Gear Jack"} className="welcomeImage"/>
                    <h5>Transmission Gear Jacks</h5>
                </div>
                <div className="wel-box coman-box">
                    <StaticImage objectFit="contain" src={pipeBender}  alt={"Pipe Bender"} className="welcomeImage"/>
                    <h5>Pipe Benders</h5>
                </div>
                <div className="wel-box coman-box">
                    <StaticImage objectFit="contain" src={engineCrane}  alt={"Engine Crane"} className="welcomeImage"/>
                    <h5>Engine Cranes</h5>
                </div>
                <div className="wel-box coman-box">
                    <StaticImage objectFit="contain" src={palletStacker}  alt={"Manual Pallet Stacker"} className="welcomeImage"/>
                    <h5>Manual Pallet Stackers</h5>
                </div>
                <div className="wel-box coman-box">
                    <StaticImage objectFit="contain" src={trolleyJack}  alt={"Trolley Jack"} className="welcomeImage"/>
                    <h5>Trolley Jacks</h5>
                </div>
                <div className="wel-box coman-box">
                    <StaticImage objectFit="contain" src={carLifter}  alt={"Car Lift Jack"} className="welcomeImage"/>
                    <h5>Car Lift Jacks</h5>
                </div>
                <div className="wel-box coman-box">
                    <StaticImage objectFit="contain" src={palletJack}  alt={"Hand Pallet jack"} className="welcomeImage"/>
                    <h5>Hand Pallet Jacks</h5>
                </div>
                <div className="wel-box coman-box">
                    <StaticImage objectFit="contain" src={positionJack}  alt={"Vehicle Positioning jack"} className="welcomeImage"/>
                    <h5>Vehicle Positioning Jacks</h5>
                </div>
            </Slider>
        </>
    )
}