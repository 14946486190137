import React from 'react';
import { Container, Button } from 'reactstrap';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import './Tdsbanner.css';

import { StaticImage } from 'gatsby-plugin-image';

const image = "../images/main-banner.jpg";
const image2 = "../images/main-banner1.jpg";
const image3 = "../images/checklist.jpg";

class Tdsbanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            responsive: {
                0: {
                    nav: false
                },
                767: {
                    nav: true
                },

            },
        }
    }

    scrollTo = (element) => {

        this.setState({
            activeMenu: element
        });

        const elementPosition = document.getElementById(element).offsetTop;
        window.scrollTo({
            top: elementPosition,
            behavior: "smooth"
        });
    };

    owlCarousel() {
        if(typeof window !== 'undefined') {
            const OwlCarousel = require('react-owl-carousel');

            return (
                <OwlCarousel
                    className="banner-owl owl-theme"
                    loop={true}
                    margin={0}
                    autoplay={true}
                    autoplayTimeout={15000}
                    smartSpeed={1000}
                    nav={true}
                    navText={["Back", "Next"]}
                    items="1"
                    responsive={this.state.responsive}
                    dots={true} >
                    <div className="item">
                        <div className="banner-one banner-img">
                            <Container>
                                <StaticImage src={image} alt={"banner"} className="bannerImage" />
                                <div className="banner-text text-center">
                                    <h1><span>Opal</span> Hydraulic</h1>
                                    <p>
                                        With more than 40 years of experience in establishment and operation of
                                        production lines of all different types of manual hydraulic jacks,
                                        we are prepared to offer our clients the best restoring services they deserve.
                                    </p>
                                    <div className="d-flex justify-content-center">
                                        <Button color="primary mr-3" onClick={() => this.scrollTo('quote')}>Get a quote now</Button>
                                        {/*<Tdsvideomodal></Tdsvideomodal>*/}
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div>
                    <div className="item">
                        <div className="banner-one banner-img">
                            <Container>
                                <StaticImage src={image3} alt={"banner"} className="bannerImage" />
                                <div className="banner-text text-center">
                                    <h1><span>Testing</span> services are available.</h1>
                                    <p>
                                        Opal Hydraulic provides one-time or regular testing services for your hydraulic jacks to insure highest efficiency at all times.
                                    </p>
                                    <div className="d-flex justify-content-center">
                                        <Button color="secondary mr-3" onClick={() => window.location = "/maintenance"}>Learn more</Button>
                                        <Button color="primary mr-3" onClick={() => this.scrollTo('quote')}>Get a quote</Button>
                                        {/*<Tdsvideomodal></Tdsvideomodal>*/}
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div>
                    <div className="item">
                        <div className="banner-one banner-img">
                            <Container>
                                <StaticImage src={image2} alt={"banner"} className="bannerImage" />
                                <div className="banner-text text-center">
                                    <h1>We <span>Repair.</span> or We <span>Replace.</span></h1>
                                    <p>
                                        Opal Hydraulic offers replacements in exchange for your defective equipment.
                                    </p>
                                    <div className="d-flex justify-content-center">
                                        <Button color="primary mr-3" onClick={() => this.scrollTo('shop')}>Shop our range of Products</Button>
                                        {/*<Tdsvideomodal></Tdsvideomodal>*/}
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div>
                </OwlCarousel>
            )
        } else {
            return null
        }
    }

    render() {
        return (
            <section className="banner">
                {
                   this.owlCarousel()
                }
            </section>
        );
    }
}


export default Tdsbanner;
