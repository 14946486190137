/**
 * Entry application component used to compose providers and render Routes.
 * */
import 'bootstrap/dist/css/bootstrap.css'

import React, {useState} from 'react';
import {ReferenceDataContextProvider} from "../businessLogic/ReferenceDataContext";
import {
    Container, Row, Col, Input
} from 'reactstrap';

import '../icofont.min.css';

// Web theme color root css
import '../components/colors.css';

import '../App.css';
import '../responsive.css';

import Helmet from 'react-helmet';

import Tdsheader from '../components/Tdsheader';
import Tdsfooter from '../components/Tdsfooter';

import Tdsbanner from '../components/Tdsbanner';

import Tdsproducts from "../components/Tdsproducts";

import QuoteForm from "../components/QuoteForm";
import Tdswelcome from "../components/Tdswelcome";
import Tdscontact from "../components/Tdscontact";
import Link from "gatsby-link";

function App(){
    const [isForQuote, setIsForQuote] = useState(undefined)

    return (
        <div className="App" >
            {/* Navbar  */}
            <Tdsheader active={'home'} isHome={true} />

            {/* Hero Section Start */}
            <div id="home" className="section">
                <Tdsbanner />
            </div>

            {/* Welcome Section Start */}
            <div className="welcome-box grapping">
               <Tdswelcome />
            </div>

            {/* Our Services Section Start */}
            <div className="section ourservice grapping" id="services">
                <Container>
                    <div className="title-box">
                        <h2>Our <b>Services</b></h2>
                        <p>Some of most important services we offer:</p>
                    </div>
                    <Row>
                        <Col xs="12" sm="6" md="6" lg="4">
                            <Link to={"/about#maintenance"}>
                                <div className="white-bg coman-box ourservice-box">
                                    <i className="icofont-list" />
                                    <h4>Maintenance</h4>
                                    <p>We offer one-time and periodical testing and maintaining services.</p>
                                </div>
                            </Link>
                        </Col>
                        <Col xs="12" sm="6" md="6" lg="4">
                            <Link to={"/about#repair"}>
                                <div className="white-bg coman-box ourservice-box">
                                    <i className="icofont-repair" />
                                    <h4>Repair/Restore</h4>
                                    <p>We repair all types of manual hydraulic jacks.</p>
                                </div>
                            </Link>
                        </Col>
                        <Col xs="12" sm="6" md="6" lg="4" className={"offset-sm-3 offset-lg-0"}>
                            <Link to={"/about#replace"}>
                                <div className="white-bg coman-box ourservice-box">
                                    <i className="icofont-recycle" />
                                    <h4>Replace/Recycle</h4>
                                    <p>We offer brand-new replacements for your defective equipment.</p>
                                </div>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Quote Section Start */}
            <div id={"quote"} className="section workwithus grapping">
                <Container>
                    <div className="title-box mb-0">
                        <h2><b>Want to repair your hydraulic jacks or to get them tested?</b></h2>
                        <p className="mb-4">
                            Lets start with giving you a free quote.
                        </p>
                        <p>
                            Please specify what type of service you are after: <br />

                            <Input type={"radio"} value={true} name={"quote-type"} checked={isForQuote === true}
                                   onChange={(e) => {setIsForQuote(e.target.checked)}}/> Repair Services
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Input type={"radio"} value={false} name={"quote-type"} checked={isForQuote === false}
                                   onChange={(e) => {setIsForQuote(!e.target.checked)}}/> Testing Services
                        </p>
                        {/*<iframe
                            id="JotFormIFrame-211432446904855"
                            title="Opal Quote Form"
                            onLoad={window.parent.scrollTo(0,0)}
                            allowFullScreen={true}
                            allow="geolocation; microphone; camera"
                            src="https://form.jotform.com/211432446904855"
                            frameBorder="0"
                            scrolling="no"
                            className="jotform">
                        </iframe>*/}
                        <QuoteForm isForQuote={isForQuote}/>
                    </div>

                </Container>
            </div>

            {/* Shop Section Start*/}
            <div className="section products grapping" id="shop">
                <Tdsproducts />
            </div>

            {/* Contact Us Section Start  */}
            <div className="section contactus grapping" id="contact">
                <Tdscontact />
            </div>

            {/* Footer  Section Start */}
            <div className="">
                <Tdsfooter/>
            </div>
        </div>
    );
}

export default function IndexPage() {
    return (
        <>
            <Helmet>
                <title>Opal Hydraulic</title>
                <meta name="description" content={"Opal Hydraulic - Repairing Hydraulic Jack Based Equipment."} />
                <meta property="og:description" content={"Opal Hydraulic - Repairing Hydraulic Jack Based Equipment."} />
                <meta property="og:title" content={"Opal Hydraulic"} />
                <meta property="og:type" content="LocalBusiness" />
                <script type="application/ld+json">
                    {`
                        {
                          "@context" : "http://schema.org",
                          "@type" : "LocalBusiness",
                          "url": "https://www.opalhydraulic.com.au",
                          "name" : "Opal Hydraulic",
                          "image" : "https://opalhydraulic.com.au/static/media/bottleJack.1faaa77b.png",
                          "email" : "opalhydraulic@gmail.com",
                          "address" : {
                            "@type" : "PostalAddress",
                            "streetAddress" : "U 16 15 Stud Road",
                            "addressLocality" : "Bayswater",
                            "addressRegion" : "VIC",
                            "postalCode" : "3153"
                          },
                          "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+61402273628",
                            "contactType": "Customer Support"
                          }
                        }
                  `}
                </script>
            </Helmet>
            <ReferenceDataContextProvider>
                <App/>
            </ReferenceDataContextProvider>
        </>
    );
}