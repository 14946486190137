import React, {useState, useEffect, useContext} from 'react';
import {ReferenceDataContext} from "../businessLogic/ReferenceDataContext";
import './Tdsproducts.css';
import {
    Button,
    Col,
    Input,
    Row,
    CardTitle,
    CardText,
    Card,
    CardImg,
    Container,
    InputGroupAddon,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge,
    InputGroup,
    FormGroup,
    UncontrolledPopover,
    PopoverHeader,
    PopoverBody
} from "reactstrap";
import Slider from "react-slick";
import QuoteForm from "./QuoteForm";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ProductCard({ addProduct, product }) {
    const [photoUrl, setPhotoUrl] = useState("")
    const [quantity, setQuantity] = useState(0)
    const [isForReplace, setIsForReplace] = useState(false)
    const [replaceDesc, setReplaceDesc] = useState("")

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [newFiles, setFiles] = useState([]);

    const toggleModal = () => {
        setIsModalOpen(prevState => !prevState)
    }

    useEffect(() => {
        if(typeof window !== "undefined") {
            const {downloadFile} = require("../businessLogic/Firebase");
            downloadFile(product.imageUrl).then((url) => setPhotoUrl(url))
        }
    }, [product])

    const handlePlusClick = () => {
        setQuantity(prevState => prevState + 1)
    }

    const handleMinusClick = () => {
        setQuantity(prevState => {
            return prevState - 1 >= 0 ? prevState - 1 : 0
        })
    }

    const handleCheckToggle = () => {
        setIsForReplace(prevState => !prevState)
    }

    const add = () => {
        if(quantity === 0) {
            return
        }

        if(isForReplace) {
            toggleModal()
        } else {
            addProductToList([])
        }
    }

    const addForReplace = () => {
        if(newFiles.length > 0 && replaceDesc !== "" ) {
            addProductToList(newFiles)
            toggleModal()
        }
    }

    const addProductToList = (images) => {
        addProduct({
            name: product.name + " (" + product.type + ") - " + product.code,
            qty: quantity,
            totalCost: "$" + (isForReplace ? (product.replaceDiscount * quantity) : (product.cost * quantity)),
            tradeIn: isForReplace ? "YES" : "NO",
            tradeInDesc: isForReplace ? replaceDesc : "",
            tradeInImages: images,
            tradeInMaxDiscount: "$" + product.replaceDiscount
        })

        setQuantity(0)
        setIsForReplace(false)
        setReplaceDesc("")
    }

    const onFilesChange = (e) => {
        const { files } = e.target;
        const length = (files.length + newFiles.length) - 5;

        const addedFiles = [...files];
        if(length > 0) {
            addedFiles.splice(files.length - length, length)
        }

        addedFiles.forEach((f, i) => {
            if(f.size > 10485760) {
                addedFiles.splice(i, 1)
            }
        });

        setFiles(prevState => {
            return [...new Set([...prevState, ...addedFiles])]
        });
    } ;

    const onFileRemove = (index) => {
        setFiles(prevState => {
            return prevState.filter((_, i) => {
                return i !== index
            })
        })
    };

    return (
        <Container className={"product-card-container"}>
            <Card className={"product-card"}>
                <p className={"product-card-id"}>CODE: {product.code}</p>
                <Badge className={"product-card-badge" + (product.condition.toLowerCase() === "new" ? " alert-success" : " alert-warning")}
                       pill>
                    {product.condition}
                </Badge>
                {
                    photoUrl !== "" ?
                        <CardImg top width="100%" src={photoUrl} alt={product.name} />
                        : ""
                }
                <Card body>
                    <CardTitle>
                        <Row>
                            <Col xs={9}>
                                {product.name}
                            </Col>
                            <Col className="text-right" xs={3}>
                                {"$" + product.cost}
                            </Col>
                            <Col xs={12} className={"product-card-subtitle"}>
                                {product.type}
                            </Col>
                            { product.isReplaceable ?
                                <>
                                    <Col xs={12}><br /></Col>
                                    <Col xs={9} className={"product-card-subtitle-small text-danger"}>
                                        Trade-In Discounted Price
                                    </Col>
                                    <Col xs={3} className={"product-card-subtitle-small text-danger text-right"}>
                                        {"$" + product.replaceDiscount}
                                    </Col>
                                </> : ""
                            }
                        </Row>
                    </CardTitle>
                    <CardText className={"text-dark"}>
                        {product.description}
                    </CardText>
                    <Row className={"mt-1 mb-2"} hidden={!product.isReplaceable}>
                        <Col xs={1}>
                            <input type="checkbox" id="replace" name="replace"
                                   className={"replace-checkbox"}
                                   checked={isForReplace}
                                   onChange={() => handleCheckToggle()}/>
                        </Col>
                        <Col>
                            <label htmlFor="replace" className={"replace-checkbox-text ml-1"}> I'd like to trade-in my eligible hydraulic jacks.</label>
                        </Col>
                    </Row>
                </Card>
                <InputGroup className={"quantity-group"}>
                    <div className="quantity buttons_added">
                        <input type="button" value="-" className="minus"
                               onClick={() => handleMinusClick()}/>
                        <input type="number" name="quantity" title="Qty" className="input-text qty text"
                               value={quantity}
                               size="4" readOnly />
                        <input type="button" value="+" className="plus"
                               onClick={() => handlePlusClick()} />
                    </div>
                    <InputGroupAddon addonType="append">
                        <Button onClick={() => add()}>Add</Button>
                    </InputGroupAddon>
                </InputGroup>
            </Card>
            <Modal isOpen={isModalOpen} toggle={toggleModal} size={"lg"} scrollable className={"modal"}>
                <ModalHeader toggle={toggleModal}>Tell Us About Your Items!</ModalHeader>
                <ModalBody className={"body"}>
                    <p className={"text-justify"}>
                        Please provide details about your existing items that you would like to trade-in with this product.
                        <br />
                        <br />
                        To enjoy the maximum discount, your trade-in eligible items must have the matching <strong>model</strong>, <strong>capacity/type</strong>, <strong>brand</strong> and they should preferably be in <strong>good working condition</strong>.
                        <br />
                    </p>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label htmlFor={"description"} className={"text-left"}> Please tell us about your items (model, type, brand and condition): <small className={"text-danger"}>(required)</small></label>
                                <Input type="textarea" id={"description"} name="description" placeholder="Description" required
                                       onChange={(e) => {setReplaceDesc(e.target.value)}} value={replaceDesc} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className={"mb-2 mt-3"}>
                        <Col xs={12}>
                            <label className="text-left titleLabel" htmlFor={"fileUpload"}>
                                Please upload images of your items. Maximum 5 files can be uploaded.
                                Each file must not exceed 10MB in size. <br/>
                                Accepted formats: Zip, PDF, JPG, JPEG, PNG <small className={"text-danger"}>(required)</small>
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <div className="fileUploadContainer">
                                <label htmlFor="fileUpload-replace" className="fileUpload">
                                    <i className="icofont-cloud-upload icofont-5x"/>
                                    Upload Files
                                </label>
                                <input type="file" multiple id="fileUpload-replace" onChange={(e) => onFilesChange(e)}
                                       accept="image/vnd.sealedmedia.softseal.jpg, image/jpeg, 	image/vnd.sealed.png, image/png, application/pdf, application/vnd.sealedmedia.softseal.pdf, application/zip, .pdf, .jpg, .jpeg, .png, .zip"
                                />
                            </div>
                        </Col>
                        <Col md={8}>
                            <Row>
                                {newFiles.map((file, i) =>
                                    <Col xs={12} sm={6} key={i}>
                                        <p className="text-left"> {file.name}
                                            <i className="icofont-bin ml-2 deleteIcon"
                                               role={"button"} tabIndex={i}
                                               title="Delete File"
                                               aria-label="Delete File"
                                               onClick={() => onFileRemove(i)} onKeyDown={() => onFileRemove(i)}/>
                                        </p>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color={"default"} onClick={toggleModal}>
                        Cancel
                    </Button>
                    <Button color={"primary"} onClick={addForReplace}
                            disabled={newFiles.length === 0 || replaceDesc === ""}>
                        Add
                    </Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
}

function AddedProductsList({addedProducts, removeProduct}) {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const toggleModal = () => {
        setIsModalOpen(prevState => !prevState)
    }

    const prepareData = async (products) => {
        let string = "<h4>Here is a list of requested products:</h4><ol>"
        for (const p of products) {
            string += "<li><h5>" + p.name + "</h5><ul>"
            string += "<li>Quantity: " + p.qty + "</li>"
            string += "<li>Total cost: " + p.totalCost + "</li>"
            string += "<li>Is this item for Trade-in? " + p.tradeIn + "</li>"
            string += p.tradeInDesc !== "" ? "<li>Additional information for trade-in: " + p.tradeInDesc + "</li>" : ""

            if(p.tradeInDesc !== "") {
                string += "<li>Trade-in Images:</li><ul>"

                if(typeof window !== "undefined") {
                    const {uploadFiles} = require("../businessLogic/Firebase");

                    try {
                        const urls = await uploadFiles(p.tradeInImages)

                        urls.forEach((u) => {
                            string += "<li>" + u + "</li>"
                        })
                    } catch (e) {
                        throw e
                    }
                }

                string += "</ul>"
            }

            string += p.tradeIn === "YES" ? "<li>Trade-in discounted price: " + p.tradeInMaxDiscount + "</li>" : ""
            string += "</ul></li>"
        }

        string += "</ol>"

        return string
    }

    return (
        <Container fluid>
            <Row className={"mt-3 ml-0"}>
                <Col>
                    <InputGroup className={"submit-group"}>
                        <div className="cart">
                            <Button id={"cartPopover"} className="cart-button" onClick={() => {}}>
                                <i className="icofont-shopping-cart"/>
                            </Button>
                            <Badge className={"cart-badge"} color="danger" >{addedProducts.length}</Badge>

                            <UncontrolledPopover trigger="legacy" placement="auto" target="cartPopover">
                                {({ scheduleUpdate }) => (
                                    <>
                                        <PopoverHeader>Added Products</PopoverHeader>
                                        <PopoverBody>
                                            <Container>
                                                <Row>
                                                    {addedProducts.map((product, i) =>
                                                        <Col xs={12} key={i} className={"mt-1 mb-1 mr-2 cart-item"}>
                                                            <p className="text-left text-dark">
                                                                {product.qty + "x " + product.name}
                                                                <br/>
                                                                {"(" + product.totalCost + ")"}
                                                                <i className="icofont-bin ml-2 deleteIcon"
                                                                   role={"button"} tabIndex={i}
                                                                   title="Delete Product"
                                                                   aria-label="Delete Product"
                                                                   onClick={() =>
                                                                   {
                                                                       removeProduct(i)
                                                                       scheduleUpdate()
                                                                   }
                                                                   }
                                                                   onKeyDown={() => removeProduct(i)}/>
                                                            </p>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Container>
                                        </PopoverBody>
                                    </>
                                )}
                            </UncontrolledPopover>
                        </div>
                        <InputGroupAddon addonType="append">
                            <Button className={"products-submit-button"} onClick={toggleModal}
                                    disabled={addedProducts.length === 0}>
                                Proceed with request
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>

                    <Modal isOpen={isModalOpen} toggle={toggleModal} size={"xl"} scrollable className={"modal"}>
                        <ModalHeader toggle={toggleModal}>
                            Make a Purchase Request
                        </ModalHeader>
                        <ModalBody className={"body"}>
                            <p>Please fill in the form below and submit your request to purchase or trade-in products. <br/>
                                We will endeavor to get touch with you via email within 24 hours of your request submission.</p>
                            <QuoteForm isInModal={true} isForQuote={true} modalData={prepareData(addedProducts)} />
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>
        </Container>
    )
}

function ProductsCarousel({allProducts, addProduct}) {
    const [products, setProducts] = useState([]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        className: "products-carousel",
        dotsClass: "slick-dots products-carousel-dots",
        arrows: true,
        responsive: [
            {
                breakpoint: 1120,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 690,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };

    useEffect(() => {
        setProducts([...allProducts])
    }, [allProducts])

    return (
        <>
            {
                <Slider {...settings}>
                    {
                        products.map((product, index) =>
                            <div className="products-carousel-item" id={index} key={index}>
                                <ProductCard addProduct={addProduct} product={product} />
                            </div>
                        )
                    }
                </Slider>
            }
        </>
    )
}

function Tdsproducts() {
    const [allProducts, setAllProducts] = useState([])
    const [addedProducts, setAddedProducts] = useState([])

    const {firebaseApp} = useContext(ReferenceDataContext);

    useEffect(() => {
        if(typeof window !== "undefined") {
            const {getProducts} = require("../businessLogic/Firebase");
            getProducts().then((products) => {
                setAllProducts(products)
            })
        }
    }, [firebaseApp])

    const addProduct = (product) => {
        setAddedProducts((prevState) => {
            return [...prevState, product]
        })
    }

    const removeProduct = (index) => {
        setAddedProducts((prevState) => {
            let products = prevState
            if (index > -1) {
                products.splice(index, 1);
            }

            return [...products]
        })
    }

    return (
        <>
            <div className="products-title-box title-box">
                <h2>Shop Our Latest  <b>Products</b></h2>
                <p>Request to shop our products or to trade-in your eligible defective equipment for brand-new
                    or restored alternatives with <b>massive</b> discounts.
                </p>
            </div>
            <ProductsCarousel allProducts={allProducts} addProduct={addProduct} />
            <AddedProductsList removeProduct={removeProduct} addedProducts={addedProducts} />
        </>
    );
}

export default Tdsproducts